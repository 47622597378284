import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    public httpClient: HttpClient,
    private authService: AuthService) {
  }


  email: FormControl = new FormControl('', [
    Validators.maxLength(70),
    Validators.minLength(6),
    Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
    Validators.required
  ]);

  inviaEmailForm: FormGroup = new FormGroup({
    email: this.email,
  });

  ngOnInit(): void {
  }

  inviaEmail() {
    const email : string = this.email.value;
    this.authService.requestPasswordReset(email).subscribe(e => {
      alert('ok');// Sostituire con alert decente 'controlla la tua casella di posta'
    }, err => {
      alert('si è verificato un errore');// Sostituire con alert decente
    });
  }
  get invioEmailFormControls(): any {
    return this.inviaEmailForm['controls'];
 }

}
