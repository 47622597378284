import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'app-guadagna-con-noi',
  templateUrl: './guadagna-con-noi.component.html',
  styleUrls: ['./guadagna-con-noi.component.scss']
})
export class GuadagnaConNoiComponent implements OnInit {

  constructor(
    public httpClient: HttpClient,
    public router: Router,
    private commonService: CommonService
  ) { }

  showEsito: boolean = false

  informativa = new FormControl(false);

  user_name: FormControl = new FormControl('', [
    Validators.maxLength(24),
    Validators.minLength(3),
    Validators.required
  ]);

  name: FormControl = new FormControl('', [
    Validators.maxLength(24),
    Validators.minLength(3),
    Validators.required,
  ]);

  last_name: FormControl = new FormControl('', [
    Validators.maxLength(24),
    Validators.minLength(3)
  ]);

  location: FormControl = new FormControl('', [
    Validators.maxLength(16),
    Validators.minLength(4),
    Validators.required,
  ]);

  email: FormControl = new FormControl('', [
    Validators.maxLength(70),
    Validators.minLength(6),
    Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
    Validators.required
  ]);

  mobile: FormControl = new FormControl('', [
    Validators.maxLength(14),
    Validators.minLength(8),
    Validators.required
  ]);


  chatMonellaForm: FormGroup = new FormGroup({
    user_name: this.user_name,
    name: this.name,
    last_name: this.last_name,
    email: this.email,
    location: this.location,
    mobile: this.mobile,
    informativa: this.informativa
  });

  ngOnInit(): void {
  }

  get chatMonellaFormControls(): any {
    return this.chatMonellaForm['controls'];
  }

  invia() {
    this.commonService.send(this.chatMonellaForm.value).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!!err.error && !!err.error.message) {
          alert(err.error.message);
        } else {
          alert('Si è verificato un errore');
        }
        return of(null);
      })
    ).subscribe(() => {
      this.showEsito = true
    });
  }

}
