import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


export interface ChatMonellaFormData {
  user_name: string;
  name: string;
  last_name: string;
  location: string;
  email: string;
  mobile: string;
}


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private httpClient: HttpClient) { }

  public send(data: ChatMonellaFormData) {
    return this.httpClient.post<any>(environment.restEndpoint + '/public/form/lavora-con-noi', data);
  }

}
