import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sostituzione-password',
  templateUrl: './sostituzione-password.component.html',
  styleUrls: ['./sostituzione-password.component.scss']
})
export class SostituzionePasswordComponent implements OnInit {

  constructor(
    public httpClient: HttpClient,
    private authService: AuthService,
    private activedRoute: ActivatedRoute,
    public router: Router) {
  }

  password: FormControl = new FormControl('', [
    Validators.maxLength(16),
    Validators.minLength(6),
    Validators.required
  ]);

  ripetiPassword: FormControl = new FormControl('', [
    Validators.maxLength(16),
    Validators.minLength(6),
    Validators.required
  ]);

  aggiornaPasswordForm: FormGroup = new FormGroup({
    password: this.password,
    ripetiPassword: this.ripetiPassword,
  });

  token;

  ngOnInit(): void {
    this.activedRoute.queryParams.subscribe(e => {
      if(e.token && e.token != null) {
        this.token = e.token;
      } else {
        this.router.navigate(['']);
      }
    })
  }

  passwordInserita() {
    // Bloccare il bottone se la validazione fallisce

    const password : string = this.password.value;
    const ripetiPasswrod : string = this.ripetiPassword.value;
    this.authService.passwordReset(this.token, password).subscribe(e => {
      this.router.navigate(['']); // Porta alla login oppure ad una pagina che dice Reset OK con un bottone accedi che porta alla login
    }, err => {
      alert('si è verificato un errore'); // Sostituire con alert decente
    });
    
  }

  get aggiornaPasswordFormControls(): any {
    return this.aggiornaPasswordForm['controls'];
 }

  naviga(pagina) {
    this.router.navigate([pagina]);
  }

}
