<div class="row no-gutters">
    <div class="col-lg-12">
        <div class="card-custom">
            <h2>La registrazione è avvenuta con successo ed il tuo account è stato <strong>abilitato</strong>!</h2>
            <br>
            <h4>Inizia subito a parlare con le nostre <strong>fantastiche modelle</strong>, ti aspettano 10 Coin
                <strong>gratuiti</strong>!</h4>
        </div>
    </div>
    <div class="col-lg-12 text-center">
        <h3 class="p-2 m-2">Inquadra il Qr Code con il tuo telefono per aprire la nostra Applicazione!</h3>
        <img src="/assets/appchatmonella.jpeg" class="img-fluid img-custom" alt="registrazione">
    </div>
    <div class="col-lg-12 text-center">
        <h4>Oppure</h4>
    </div>
    <div class="col-lg-12 text-center">
        <a class="btn btn-success btn-custom my-4" (click)="naviga('/')"><i class="fa fa-home"></i> TORNA ALLA HOME</a>
    </div>
</div>