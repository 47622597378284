<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <h2 class="text-center mt-2">Aggiornamento Password:</h2>
            <hr>
            <p>
                Inserisci la tua <strong>Nuova Password</strong> nei campi sottostanti per completare la modifica della
                tua password. <br>
                <br>
                <strong>Attenzione*</strong>: <br> La password deve essere ripetuta correttamente per completarene
                l'aggiornamento.
            </p>
        </div>
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
            <form [formGroup]="aggiornaPasswordForm">
                <div class="form-group row">
                    <div class="col-12">
                        <label for="password" style="font-weight: 700; color: black;">Inserire nuova Password</label>
                        <input type="password" class="form-control" formControlName="password"
                            placeholder="Inserisci password">
                    </div>
                    <div class="col-12">
                        <label for="ripetiPassword" style="font-weight: 700; color: black;">Inserire nuova
                            Password</label>
                        <input type="password" class="form-control" formControlName="ripetiPassword"
                            placeholder="Ripeti password">
                    </div>
                </div>
            </form>
            <button class="btn btn-custom btn-success mt-3" (click)="passwordInserita()"><i class="fa fa-check"></i>
                Modifica Password </button>
        </div>
        <div class="col-lg-4"></div>
    </div>
</div>