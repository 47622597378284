import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SostituzionePasswordComponent } from './pages/sostituzione-password/sostituzione-password.component';
import { HomeComponent } from './pages/home/home.component';
import { PaginaConfermaEmailComponent } from './pages/pagina-conferma-email/pagina-conferma-email.component';
import { GuadagnaConNoiComponent } from './pages/guadagna-con-noi/guadagna-con-noi.component';


const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'sostituzione-password', component: SostituzionePasswordComponent },
    { path: 'pagina-conferma-email', component: PaginaConfermaEmailComponent },
    { path: 'guadagna-con-chat-monella', component: GuadagnaConNoiComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'top', initialNavigation: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
