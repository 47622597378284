import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  public requestPasswordReset(email: string) {
    return this.http.post<any>(environment.restEndpoint + '/requestPasswordReset', { email: email });
  }

  public passwordReset(token: string, password: string) {
    return this.http.post<any>(environment.restEndpoint + '/passwordReset', { token: token, password: password });
  }

}
