<div class="container-fluid" style="background-color: #eaf1ff;">
    <br>
    <h1 class="text-center" style="color: #dc3545;">COME POSSO USARE CHATMONELLA PER GUADAGNARE</h1>

    <p>Con il nostro sito potrai chattare e <strong>guadagnare</strong> con le conversazioni che intratterrai con gli
        utenti in modo completamente anonimo e sicuro. Avrai la possibilità di scambiare <strong>foto a
            pagamento</strong> e di guadagnare fin da subito in totale discrezione. Accettiamo esclusivamente ragazze
        che parlano italiano.</p>


    <h2 style="color: #dc3545;">E' garantito il mio anonimato?</h2>

    <p>Assolutamente sì, il cliente pagherà direttamente ChatMonella senza conoscere i dati dell’operatrice. E'
        richiesta esclusivamente la verifica delle tue credenziali tramite form sottostante. Una volta che il tuo
        account sarà attivo, potrai iniziare a guadagnare.</p>

    <h2 style="color: #dc3545;">Come e quando verrò pagata?</h2>

    <p>Puoi scegliere di essere pagata con una delle seguenti modalità: <strong>bonifico bancario</strong>,
        <strong>ricarica PostePay</strong> o <strong>PayPal</strong>. I pagamenti verranno effettuati una volta al mese
        al raggiungimento minimo di <strong>euro 100,00</strong>. I pagamenti verranno scadenzati ogni 6 del mese
        successivo alla maturazione dei crediti, in base ad un report in tempo reale che sarà possibile leggere nella
        propria pagina operatore in qualsiasi momento. Il tutto è trasparente e chiaro al 100%.
    </p>

    <h2 style="color: #dc3545;">Qual è il minimo per incassare?</h2>

    <p>Il minimo per richiedere il versamento dei guadagni è di <strong>100 € nette</strong> che saranno accreditati una
        volta al mese.</p>

    <h2 style="color: #dc3545;">Quanto costa il vostro servizio?</h2>

    <p>Il servizio è totalmente <strong>gratuito</strong> lato operatore.</p>

    <h2 style="color: #dc3545;">Come posso vendere le foto?</h2>

    <p>Tramite l’invio immagini apparirà una finestra con la tipologia di "<strong>Foto a pagamento</strong>". Ad ogni
        vendita ti verranno accreditati i relativi coin in base alla tipologia di contenuto.</p>
    <ul>
        <li><strong>Foto mani</strong> = 5 crediti</li>
        <li><strong>Foto piede con calzino</strong> = 10 crediti</li>
        <li><strong>Foto piede con collant</strong> = 10 crediti</li>
        <li><strong>Foto piedi nudi</strong> = 20 crediti</li>
        <li><strong>Foto gambe con autoreggenti</strong> = 30 crediti</li>
        <li><strong>Foto in intimo</strong> = 50 crediti</li>
        <li><strong>Foto del seno</strong> = 100 crediti</li>
        <li><strong>Altre tipologie di foto hard</strong> = 200 crediti</li>
    </ul>
    <p>Il cliente riceverà l’immagine offuscata e per sbloccarla dovrà pagare il corrispettivo in coin. Allo sblocco
        avverrà l’accredito automatico.</p>

    <h2 style="color: #dc3545;">E' richiesta l'esclusiva?</h2>

    <p>Assolutamente no, puoi lavorare anche in altri siti, ma è comunque:</p>
    <ol>
        <li><strong>VIETATO</strong> proporre in chat o sulla propria pagina nel sito i contatti personali</li>
        <li><strong>VIETATO</strong> accettare dai clienti pagamenti alternativi.</li>
        <li><strong>VIETATO</strong> promuovere i propri prodotti o siti terzi fuori dal circuito ChatMonella.</li>
    </ol>
    <p>Ci riserviamo comunque il diritto di sospendere un profilo in caso ci risulti poco attivo e di bannare le
        operatrici scorrette.</p>

    <h2 style="color: #dc3545;">In quali altri casi si può essere bannati?</h2>

    <p>In caso di utilizzo di <strong>foto o video falsi</strong> o comportamenti illegali, il profilo sarà bannato e il
        credito perso.</p>

    <h2 style="color: #dc3545;">Posso avere più di un profilo?</h2>

    <p>E’ possibile avere più di un profilo anche se è sconsigliato, in quanto sarebbe impossibile gestire più utenze
        sullo stesso dispositivo e avendo ognuno un solo numero di telefono associato per ricevere le notifiche.</p>

    <h2 style="color: #dc3545;">Ho deciso, voglio iscrivermi! Cosa devo fare?</h2>
    <p>Semplicissimo! Compila il form qui sotto e attendi che la tua richiesta venga elaborata. Un nostro operatore ti
        risponderà in 2/48h e verrai attivata.</p>

    <div class="card-chat-monella">
        <div class="row">
            <div class="col-lg-12 text-center mb-2">
                <h2 style="color: #dc3545;">Entra a far parte di chat monella</h2>
            </div>

            <div class="col-lg-3">

            </div>
            <div class="col-lg-6">
                <form class="card-content-2" [formGroup]="chatMonellaForm"
                    style="display: flex; justify-content:center; flex-direction: column;">

                    <div class="row mb-2">
                        <div class="col-3">
                            <label style="font-weight: bold;">Alias*</label>
                        </div>

                        <div class="col-9">
                            <input type="text" class="form-control" formControlName="user_name"
                                placeholder="Scegli un nickname">
                            <div *ngIf="chatMonellaFormControls.user_name.invalid && (chatMonellaFormControls.user_name.dirty || chatMonellaFormControls.user_name.touched)"
                                class="alert alert-danger form-danger" role="alert">

                                <div *ngIf="chatMonellaFormControls.user_name.errors">
                                    Inserire un alias di almeno 3 Caratteri
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="row mb-2">

                        <div class="col-3"> <label style="font-weight: bold;">Nome*</label></div>

                        <div class="col-9">
                            <input type="text" class="form-control" formControlName="name" placeholder="Il tuo nome">
                            <div *ngIf="chatMonellaFormControls.name.invalid && (chatMonellaFormControls.name.dirty || chatMonellaFormControls.name.touched)"
                                class="alert alert-danger form-danger" role="alert">

                                <div *ngIf="chatMonellaFormControls.name.errors">
                                    Inserire un nome utente di almeno 3 Caratteri
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-3"> <label style="font-weight: bold;">Cognome</label></div>
                        <div class="col-9"> <input type="text" class="form-control" formControlName="last_name"
                                placeholder="Il tuo cognome">
                            <div *ngIf="chatMonellaFormControls.last_name.invalid && (chatMonellaFormControls.last_name.dirty || chatMonellaFormControls.last_name.touched)"
                                class="alert alert-danger form-danger" role="alert">

                                <div *ngIf="chatMonellaFormControls.last_name.errors">
                                    Inserire un cognome di almeno 3 Caratteri
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-3"><label style="font-weight: bold;">Email*</label></div>
                        <div class="col-9"> <input type="email" class="form-control" formControlName="email"
                                placeholder="Inserisci Email">
                            <div *ngIf="email.touched && email.invalid">
                                <div *ngIf="email.errors.required" class="alert alert-danger" color="warning">L'
                                    indirizzo email è
                                    obbligatorio
                                </div>
                                <div *ngIf="email.errors?.pattern || email.errors?.minlength || email.errors?.maxlength"
                                    color="warning">L' indirizzo email non è valido</div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">

                        <div class="col-3"> <label style="font-weight: bold;">Città*</label></div>

                        <div class="col-9">
                            <input type="text" class="form-control" formControlName="location" placeholder="Città">
                            <div *ngIf="chatMonellaFormControls.location.invalid && (chatMonellaFormControls.location.dirty || chatMonellaFormControls.location.touched)"
                                class="alert alert-danger form-danger" role="alert">

                                <div *ngIf="chatMonellaFormControls.location.errors">
                                    Inserire una città valida
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-3"> <label style="font-weight: bold;">Cellulare*</label></div>
                        <div class="col-9">
                            <input type="text" class="form-control" formControlName="mobile"
                                placeholder="Inserisci Cellulare">
                            <div *ngIf="mobile.touched && mobile.invalid">
                                <div *ngIf="mobile.errors.required" class="alert alert-danger" color="warning">L'
                                    Il numero di cellulare è
                                    obbligatorio
                                </div>
                                <div *ngIf="mobile.errors?.pattern || mobile.errors?.minlength || mobile.errors?.maxlength"
                                    color="warning">Il numero di cellulare non è valido</div>
                            </div>
                        </div>
                    </div>

                    <div class="radio-style" style="color: black !important; margin-left: 3rem; margin-top: 1rem;">
                        <input class="form-check-input" formControlName="informativa" type="checkbox"
                            style="margin-right: 8px;" value="" id="defaultCheck1">
                        <div class="radio-style-2">
                            Acconsento al trattamento dei miei dati personali secondo l'<a style="color: black;"
                                href="...">informativa privacy</a>.
                        </div>
                    </div>


                </form>
            </div>
            <div class="col-12 col-lg-12 text-center">
                <button class="btn btn-custom btn-success" [disabled]="!chatMonellaForm.valid"
                    (click)="invia()">Invia</button>
            </div>
        </div>

        <div *ngIf="showEsito == true"
            style="width: 100%; display: flex; flex-direction: row; justify-content: center; align-items: center;">
            <div class="esito-wrapper mt-4 text-center">
                <p style="color: rgb(0, 91, 0);">
                    <strong>Grazie!</strong>
                    Abbiamo ricevuto la tua richiesta e verrà elaborata il prima possibile.
                </p>
            </div>
        </div>

    </div>
</div>