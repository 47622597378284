<div class="container-fluid header-container animate__animated animate__fadeIn animate__delay-1s">
    <div class="mob-only mt-1">
        <h2>Scarica l'app di Chat Monella!</h2>
        <hr>
        <p style="color: black;">In pochissimi e semplicissimi passaggi potrai utilizzare la nostra comodissima
            applicazione per Andorid e iOS!</p>
    </div>
    <div class="row desktop">
        <div class="col-lg-1"></div>
        <div class="col-lg-6 my-auto text-center">
            <h2 style="font-size: 36px;">Scarica Gratuitamente l'app ed inizia subito a chattare con le nostre
                Donne!</h2>
            <h1 style="color: red; font-size: 34px;">Chat Monella, le migliori Donne sul WEB.</h1>
            <ul style="list-style-type: none;">
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Scarica la nostra app GRATIS!</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Registrati <strong>Gratuitamente</strong>!</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Ricevi <strong>Coin Gratuiti</strong> ed inizia a chattare con le nostre Donne!</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Chatta <strong>quanto e come vuoi</strong> con ognuna di loro!</h4>
                </li>
            </ul>
            <a class="btn btn-custom head text-center bg-danger" href="https://app.chatmonella.it/login"><i
                    class="fa fa-globe"></i> UTILIZZA DAL WEB</a>
            <a class="btn btn-custom head text-center bg-danger" href="https://app.chatmonella.it/registrazione"><i
                    class="fa fa-download"></i> SCARICA
                APP!</a>
        </div>
        <div class="col-lg-4 text-center">
            <a href="https://app.chatmonella.it/registrazione"><img src="/assets/pre3.png" class="img-fluid custom-img"
                    alt="chat Monella"></a>
        </div>
        <div class="col-lg-1"></div>
    </div>
    <div class="row mobile">
        <div class="col-lg-1"></div>
        <div class="col-lg-4 text-center">
            <a href="https://app.chatmonella.it/registrazione"><img src="/assets/pre3.png" class="img-fluid custom-img"
                    alt="chat Monella"></a>
        </div>
        <div class="col-lg-6 my-auto text-center">
            <h2 style="font-size: 36px;">Scarica Gratuitamente l'app ed inizia subito a chattare con le nostre
                Donne!</h2>
            <h1 style="color: red; font-size: 34px;">Chat Monella, le migliori Donne sul WEB.</h1>
            <ul style="list-style-type: none;">
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Scarica la nostra app;</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Registrati <strong>Gratuitamente</strong>!</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Ricevi Coin Gratuiti ed inizia a chattare con le nostre Donne!</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Chatta <strong>quanto e come vuoi</strong> con ognuna di loro!</h4>
                </li>
            </ul>
            <a class="btn btn-custom text-center bg-danger" href="https://app.chatmonella.it/registrazione"> SCARICA
                APP!</a>
        </div>

        <div class="col-lg-1"></div>
    </div>
</div>
<div class="container-fluid main-container bg-white animate__animated animate__fadeIn animate__delay-2s">
    <div class="row">
        <div class="col-lg-6">
            <img src="/assets/hero-image-chatting.png" class="img-fluid" alt="chat Monella">
        </div>
        <div class="col-lg-6 my-auto">
            <h2>Chatta con chi vuoi, In qualsisi momento ed ovunque ti trovi!</h2>
            <hr>
            <p>
                Non ci sarà mai un momento in cui non sarai libero di chattare con la tua ragazza preferita, con la
                nostra applicazione, veloce e comoda, potrai dare libero sfogo alle tue fantasie. Tutte le nostre
                Donne sono esperte e pronte a soddisfare ogni tua voglia in qualsiasi momento della giornata!
                <br>
                <br>
                <strong style="font-size: 28px;">Cosa stai aspettando?</strong>
            </p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row" style="border-top: 1.5px solid gray;">
        <div class="col-lg-12 text-center">
            <h2>COME SCARICARE LA NOSTRA APP PER ANDROID</h2>

            <h3>Se sei in possesso di un dispositivo <strong>ANDROID</strong> segui questi semplici passaggi:</h3>
        </div>
    </div>
    <div class="container mb-4">
        <div class="row">
            <div class="col-lg-6 col-6 text-center">
                <img src="/assets/2.png" class="img-fluid custom-ando" alt="">
            </div>
            <div class="col-lg-6 col-6 my-auto">
                <h3>Passo 1:</h3>
                <hr style="margin-right: 65%;height: 1.5px; background-color: red;">
                <p>Appena avrai eseguito con successo la Registrazione, clicca sulla spunta in basso
                    "<strong>Aggiungi Chat Monella alla pagina home</strong>"</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-6 my-auto">
                <h3>Passo 2:</h3>
                <hr style="margin-right: 65%;height: 1.5px; background-color: red;">
                <p>Clicca sul tasto "<strong>Aggiungi</strong>" nella finestra che si aprirà per aggiungere l'icona
                    nella home del tuo telefono!</p>
            </div>
            <div class="col-lg-6 col-6 text-center">
                <img src="/assets/3.png" class="img-fluid custom-ando" alt="">
            </div>

        </div>
        <div class="row">
            <div class="col-lg-6 col-6 text-center">
                <img src="/assets/4.png" class="img-fluid custom-ando" alt="">
            </div>
            <div class="col-lg-6 col-6 my-auto">
                <h3>Passo 3:</h3>
                <hr style="margin-right: 65%;height: 1.5px; background-color: red;">
                <p>Attendi che il tuo telefono Scarichi e Scansioni la nostra app,<strong> e la tua applicazione
                        sarà pronta per essere utilizzata dalla schermata HOME!</strong></p>
                <hr>
                <a href="https://app.chatmonella.it/registrazione" style="margin: auto;"
                    class="btn btn-custom bg-danger"><i class="fa fa-download"></i> SCARICA PER ANDROID</a>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" style="border-top: 2px solid rgba(49, 49, 49, 0.353);">
    <div class="row">
        <div class="col-lg-12 text-center">
            <h2>COME FUNZIONA LA NOSTRA APP?</h2>
        </div>
        <div class="col-lg-6 text-center">
            <img src="/assets/model-chatting.png" class="img-fluid" alt="chat Monella">
        </div>
        <div class="col-lg-6 my-auto">
            <h3>Utilizzare la nostra app è semplicissimo:</h3>
            <hr>
            <ul style="list-style-type: none;">
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Scarica la nostra app;</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Registrati <strong>Gratuitamente</strong>!</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Ricevi Coin Gratuiti ed inizia a chattare con le nostre Donne!</h4>
                </li>
                <li class="list-item">
                    <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                    <h4>Chatta <strong>quanto e come vuoi</strong> con ognuna di loro!</h4>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="row" style="border-top: 1.5px solid gray;">
        <div class="col-lg-12 text-center">
            <h2>COME SCARICARE LA NOSTRA APP PER iOS!</h2>

            <h3>Se sei in possesso di un dispositivo <strong>iOS</strong> segui questi semplici passaggi:</h3>
        </div>
    </div>
    <div class="container mb-4">
        <div class="row">
            <div class="col-lg-6 col-12 text-center no-gutters">
                <img src="/assets/iphone-xr.png" class="img-fluid custom-fluid" alt="video spiegazione">
                <video class="video-m" playsinline [muted]="true" width="340" height="600" autoplay loop>
                    <source src="/assets/video/ios-app.mp4" type="video/mp4">
                    <source src="/assets/video/ios-app.ogg" type="video/ogg">
                    Your browser does not support the video tag.
                </video>
            </div>
            <div class="col-lg-6 col-12 my-auto ios-margins">
                <h3>Pochi passaggi:</h3>
                <hr style="height: 1.5px;">
                <ul style="list-style-type: none;">
                    <li class="list-item-ios" style="margin-bottom: 0px;">
                        <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                        <h4>Clicca sul pulsante <strong>Share</strong> nel menu in basso al centro;</h4> <br>
                    </li>
                   <div style="text-align: center;"> <img src="/assets/share.png" style="max-width: 180px; margin: auto;" class="img-fluid" alt=""></div>
                    <li class="list-item-ios mt-3">
                        <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                        <h4>Clicca su <strong>Aggiungi alla schermata Home</strong> e conferma;</h4>
                    </li>
                    <li class="list-item-ios">
                        <i class="fa fa-check" style="color: green; font-size: 28px; margin-right: 4px;"></i>
                        <h4>Completa l'installazione premendo in alto a destra <strong>"Aggiungi".</strong></h4>
                    </li>
                </ul>
                <hr>
                <a href="https://app.chatmonella.it/registrazione" class="btn btn-custom bg-danger"
                    style="margin: auto;"><i class="fa fa-download"></i> SCARICA PER iOS</a>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" id="password">
    <h2 class="text-center">Come recuperare e sostituire la tua <strong>Password</strong>!</h2>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="text-center mt-2">Recupero dati di accesso:</h2>
                <hr>
                <p>
                    Inerisci la tua E-mail ed premi sul tasto "<strong>Invia Email</strong>". Ti verra spedita una Email
                    per
                    riconfigurare la tua password se l'hai dimenticata o nel caso qualcuno sia venuto a conoscenza della
                    tua
                    precedente password.
                </p>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4 text-center p-4 m-2">
                <form [formGroup]="inviaEmailForm">
                    <label for="email" style="font-weight: 700; color: black;">Inserire E-mail</label>
                    <input type="email" class="form-control" formControlName="email" placeholder="Inserisci Email">
                </form>
                <button class="btn btn-custom-pass btn-danger mt-3" (click)="inviaEmail()"><i class="fa fa-send"></i>
                    Invia
                    Email
                </button>
            </div>
            <div class="col-lg-4"></div>
        </div>
    </div>
</div>